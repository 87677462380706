import {
  HOMEOWNER_OPTIONS,
  RADIO_ERROR_MESSAGE,
  ZIP_CODE_ERROR_MESSAGE,
  ZIP_CODE_REGEX,
} from '../../../lib/constants';
import {
  debounceUpdate,
  getErrorMessage,
  handleKeyDown,
  isZipCodeValid,
  onlyNumbersInput,
} from '../../../lib/utils';
import { useRef, useState } from 'react';

import { ThreeDots } from 'react-loader-spinner';
import isEmpty from 'lodash/isEmpty';
import { useFormContext } from 'react-hook-form';
import { useFormDataContext } from '../../../lib/contexts';
import { useScrollToTop } from '../../../lib/hooks';
function FormStep1() {
  const [isValidatingZip, setIsValidatingZip] = useState(false);
  const {
    register,
    setValue,
    trigger,
    handleSubmit,
    formState,
    setError,
    clearErrors,
  } = useFormContext();
  const { updateStep, updateFormData, formData } = useFormDataContext();
  const { errors } = formState;
  const zipRef = useRef();

  const handleClick = (event) => {
    event.stopPropagation();
    if (event.target.type !== 'radio') {
      const input = event.currentTarget.querySelector('input[type="radio"]');
      input.click();
    }
  };

  const handleClickContinue = handleSubmit(async (_data) => {
    const isValid = await trigger(['zip']);

    if (!isValid) {
      const [firstErrorKey] = Object.keys(errors);
      const errorRefs = {
        zip: zipRef,
      };

      errorRefs[firstErrorKey]?.current?.focus();
    } else {
      updateStep(formData.step + 1);
    }
  });

  const handleUpdateZipCode = async (data) => {
    setIsValidatingZip(true);
    const { error, ...resultData } = await isZipCodeValid(data.zip);
    updateFormData(resultData);

    if (error) {
      setError('zip', { message: ZIP_CODE_ERROR_MESSAGE });
    } else {
      clearErrors('zip');
      setValue('city', resultData.city);
      setValue('state', resultData.state);
      setValue('zip', resultData.zip);
    }
    setIsValidatingZip(false);
  };

  useScrollToTop();
  return (
    <div className="form-step">
      <div className="form-step__label">Where is your window project?</div>

      <div className="form-step__input-container">
        <input
          type="text"
          placeholder="Zip Code"
          ref={zipRef}
          onKeyDown={handleKeyDown}
          className={`form-step__input ${
            errors.zip && 'form-step__input-error'
          }`}
          maxLength="5"
          inputMode="numeric"
          onInput={onlyNumbersInput}
          autoComplete="off"
          {...register('zip', {
            required: ZIP_CODE_ERROR_MESSAGE,
            pattern: {
              value: ZIP_CODE_REGEX,
            },
            validate: () => {
              if (formData.city === '') return ZIP_CODE_ERROR_MESSAGE;
            },
          })}
          onChange={(e) =>
            debounceUpdate('zip', e.target.value, handleUpdateZipCode)
          }
        />
      </div>
      {!isEmpty(errors) && (
        <div className="form-step__error-box">{getErrorMessage(errors)}</div>
      )}
      <input
        onClick={handleClickContinue}
        className="form-step__btn"
        disabled={Object.keys(errors).length > 0}
        type="button"
        value="START FREE QUOTE"
      />
    </div>
  );
}

export default FormStep1;
